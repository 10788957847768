<template>
  <FormKit
    type="autocomplete"
    selection-removable="yes"
    :options="passportNationalities"
    v-bind="props"
  />
</template>
<script setup lang="ts">
import passportNationalities from '@/data/passportNationalities.json'
const props = defineProps<{ [key: string]: any }>()
</script>
